import * as React from "react"

import LayoutSub from "../../components/layoutSub";
import SEO from "../../components/seo";

function NotFoundPage({ location, pageContext }) {

  const { breadcrumb: { crumbs } } = pageContext;

  const {pathname} = location;
  const pagetitle = "Page Not found";
  const description = "Page Not found";

  return (
    <LayoutSub  crumbs={crumbs} pathname={pathname}  lang='en'  >
                  <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
            lang="en"
          />
    <section>

      <h1 className=" my-2 heading-b">Page not found</h1>
      <p>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        We couldn&apos;t find what you were looking for.
       
      </p>
    </section>
    </LayoutSub>
  )
}

export default NotFoundPage
